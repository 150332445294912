import { Configuration } from "../../../types/Configuration";
import { ParameterError, ValidationError } from "../ValidationError";
import { validateRoutine } from "./utilities";

export const refractiveIndexChangeDetector = (configuration: Configuration): ValidationError[] => {
    if (configuration.assaySequence.length === 0) {
        throw new ValidationError("Assay sequence was empty")
    }

    const errors: ValidationError[] = validateRoutine(configuration, "RefractiveIndexChangeDetector", {
        threshold: ["number", (value: any) => {
            if (value < 0) {
                return [new ParameterError("Threshold must be greater than or equal to 0")]
            }
            return []
        }]
    })

    return errors
}
