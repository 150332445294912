import { Configuration } from "../../../types/Configuration";
import { ParameterError, ValidationError } from "../ValidationError";
import { validateRoutine } from "./utilities";

/**
 * Checks that the spot indexes specified in the "refractiveIndexChangeFlowCalculator"
 * routine exist according to the sensorConfiguration.
 * @param configuration The configuration object.
 * @returns A list of validation errors if spot indexes are invalid.
 */
export const refractiveIndexChangeFlowCalculator = (configuration: Configuration): ValidationError[] => {
    if (configuration.assaySequence.length === 0) {
        throw new ValidationError("Assay sequence was empty")
    }

    const sensorConfig = configuration.sensorConfiguration;
    const maxValidIndex = sensorConfig.numColumns * sensorConfig.numRows;

    const errors: ValidationError[] = validateRoutine(configuration, "RefractiveIndexChangeFlowRateCalculatorStepRoutine", {
        firstColumnSpots: ["array", (value: any) => {
            const errors: ValidationError[] = [];
            value.forEach((spotIndex: any) => {
                if (
                    typeof spotIndex !== 'number' ||
                    spotIndex < 1 ||
                    spotIndex > maxValidIndex
                ) {
                    errors.push(new ParameterError(
                        `Spot index "${spotIndex}" is invalid (valid spot indexes are between 1 and ${maxValidIndex})`
                    ));
                }
            });
            return errors;
        }],
        lastColumnSpots: ["array", (value: any) => {
            const errors: ValidationError[] = [];
            value.forEach((spotIndex: any) => {
                if (
                    typeof spotIndex !== 'number' ||
                    spotIndex < 1 ||
                    spotIndex > maxValidIndex
                ) {
                    errors.push(new ParameterError(
                        `Spot index "${spotIndex}" is invalid (valid spot indexes are between 1 and ${maxValidIndex})`
                    ));
                }
            });
            return errors;
        }],
        threshold: ["number", (value: any) => {
            if (value < 0) {
                return [new ParameterError("Threshold must be greater than or equal to 0")]
            }
            return []
        }]
    })

    return errors;
};
