import { Configuration } from "../../../types/Configuration";
import { ValidationError } from "../ValidationError";
import { validateRoutine } from "./utilities";

export const dryWetFlowRateCalculator = (configuration: Configuration): ValidationError[] => {
    if (configuration.assaySequence.length === 0) {
        throw new ValidationError("Assay sequence was empty")
    }

    const errors: ValidationError[] = validateRoutine(configuration, "DryWetFlowRateCalculator", {})

    return errors
}
