
import { Heading, Grid, Card, View, Button, Alert, Divider, Table, TableHead, TableBody, TableCell, TableRow } from "@aws-amplify/ui-react"
import '@aws-amplify/ui-react/styles.css';
import { DownloadStatusTypeString } from "../utilities/DownloadStatusType"
import { useRigResultFile } from "../hooks/useRigResultFile"
import { useTestConfigurationPath } from "../hooks/useTestConfigurationPath"
import { Chart } from "chart.js/auto";
import annotationPlugin from 'chartjs-plugin-annotation';
import { useParams, useNavigate } from 'react-router-dom'
import { FlowTestResult, IntensityResult, PersistedVariablesStep, StepInfo } from '../types/flowTestResult'
import { initiateOverviewCSVDownload, initiateDataDownload, initiateSpotsOneSecondTimeSeriesCSVDownload, initiateOneSecondTimeSeriesDownload, initiateCombinedCSVDownload, initiateVariablesTimeSeriesDownload, initiateSpotFramesDownload, initiateDryWetTransitionDownload, initiateConfigurationDownload, initiateVariableDownload } from "../utilities/DownloadUtilities";
import { PumpChart } from "./charts/PumpChart";
import { IntensityChart } from "./charts/IntensityChart";
import { EquationChart } from "./charts/EquationChart";

Chart.register(annotationPlugin);

export const ResultFlowTestRig = () => {
    const { yearMonth, dateString, filename } = useParams();
    const testFolder = `chipflowTests/${yearMonth}/${dateString}`

    const chipflowFilepath = `${testFolder}/${filename}`

    const configurationPathResult = useTestConfigurationPath(dateString, chipflowFilepath)
    let configurationPath = ""
    if (configurationPathResult.type === DownloadStatusTypeString.LOADED) {
        configurationPath = configurationPathResult.results
    }

    const chipflowFile = useRigResultFile<FlowTestResult>(chipflowFilepath)

    const intensityFilepath = `${testFolder}/${filename?.replace("chipflowTest", "channelAveragesTimeSeries")}`
    const intensityFile = useRigResultFile<[IntensityResult]>(intensityFilepath)

    const chipflowTestVariablesFilepath = `${testFolder}/${filename?.replace("chipflowTest", "chipflowTestVariables")}`
    const chipflowTestVariablesFile = useRigResultFile<[PersistedVariablesStep]>(chipflowTestVariablesFilepath)

    const BackLink = () => {
        const navigate = useNavigate();
        return <Button onClick={() => navigate(-1)}>&lt; Back</Button>
    }

    let contentToRender = <></>;
    switch (chipflowFile.type) {
        case DownloadStatusTypeString.LOADING:
            contentToRender = <Alert variation="info">Loading...</Alert>
            break;
        case DownloadStatusTypeString.ERROR:
            contentToRender = <Alert variation="error">{chipflowFile.errorString}</Alert>
            break;
        case DownloadStatusTypeString.LOADED:
            contentToRender = (
                <Grid columnGap="10rem" rowGap="0.5rem" 
                    templateColumns="auto 1fr" templateRows="auto">
                    <Card columnStart="1" columnEnd="-1">
                        <Grid templateColumns="auto 1fr">
                            <Card columnStart="1" columnEnd="1">
                                <BackLink />
                            </Card>
                            <Card columnStart="2" columnEnd="2">
                                <Heading level={3}>{filename}</Heading>
                            </Card>
                        </Grid>
                    </Card>
                    <Card columnStart="1" columnEnd="-1">
                        <Heading level={4}>Flow Rig Result</Heading>
                        <PumpChart resultFile={chipflowFile} />
                    </Card>
                    <Card columnStart="1" columnEnd="-1">
                        <Heading level={4}>Average Channel Intensity</Heading>
                        <IntensityChart resultFile={intensityFile} />
                    </Card>
                    <Card columnStart="1" columnEnd="-1">
                        <Heading level={4}>Final Equation Result</Heading>
                        <EquationChart resultFile={chipflowTestVariablesFile} />
                    </Card>
                    <Card columnStart="1" columnEnd="-1">
                    <Divider />
                    </Card>
                    <Card columnStart="1" columnEnd="1">
                        <Heading level={4}>Step Times</Heading>
                        <br />
                        <StepTable stepTimes={chipflowFile.results.stepTimes} />
                     </Card>
                     <Card columnStart="2" columnEnd="2">
                        <Heading level={4}>Downloads</Heading>
                            <br />   
                        <Button variation="primary" onClick={() => {initiateCombinedCSVDownload(filename!, chipflowFile.results, `chipflowTests/${yearMonth}/${dateString}`)}}>Download Combined CSV</Button>
                        <br />
                        <br />                    
                        <Button variation="primary" onClick={() => {initiateOverviewCSVDownload(chipflowFile.results, `${filename ?? "flowrigresult"}.csv`)}}>Download Pressure Effort Time Series Data (CSV)</Button>
                        <br />
                        <br />
                        <Button variation="primary" onClick={() => {initiateDataDownload(JSON.stringify(chipflowFile.results), `${filename ?? "flowrigresult"}.json`, "application/json")}}>Download Raw Pressure Effort Data (JSON)</Button>
                        <br />
                        <br />
                        <Button variation="primary" onClick={() => {initiateSpotsOneSecondTimeSeriesCSVDownload(filename!, `chipflowTests/${yearMonth}/${dateString}`)}}>Download Spots (1s) Timeseries <b>(CSV)</b></Button>
                        <br />
                        <br />
                        <Button variation="primary" onClick={() => {initiateOneSecondTimeSeriesDownload(filename!, `chipflowTests/${yearMonth}/${dateString}`)}}>Download Spots (1s) Timeseries <b>(JSON)</b></Button>
                        <br />
                        <br />
                        <Button variation="primary" onClick={() => {initiateVariablesTimeSeriesDownload(filename!, `chipflowTests/${yearMonth}/${dateString}`)}}>Download Variables Timeseries (JSON)</Button>
                        <br />
                        <br />
                        <Button variation="primary" onClick={() => {initiateSpotFramesDownload(filename!, `chipflowTests/${yearMonth}/${dateString}`)}}>Download Spots Frames Timeseries (JSON)</Button>
                        <br />
                        <br />
                        { (intensityFile.type === DownloadStatusTypeString.LOADED) && <Button variation="primary" onClick={() => {initiateVariableDownload(intensityFile.results, filename!, "channelAveragesTimeSeries")}}>Download Channel Averages Timeseries (JSON)</Button> }
                        <br />
                        <br />
                        <Button variation="primary" onClick={() => {initiateDryWetTransitionDownload(filename!, `chipflowTests/${yearMonth}/${dateString}`)}}>Download Dry Wet Transition Timeseries (JSON)</Button>
                        <br />
                        <br />
                        { (configurationPathResult.type === DownloadStatusTypeString.LOADED) && <Button variation="primary" onClick={() => {initiateConfigurationDownload(configurationPath)}}>Download Configuration (JSON)</Button> }                      
                     </Card>
                </Grid>
            );
            break;
    }

    return (
        <View>
            {contentToRender}
        </View>
    );
}  

const StepTable = ({stepTimes} : {stepTimes: StepInfo[]}) => {
    return <Table variation="striped" highlightOnHover={true}>
        <StepTableHeadingRow />
        <TableBody>
            {
                stepTimes.map((stepInfo, index) => {
                    const nextStepTime = (index !== stepTimes.length - 1) ? stepTimes[index + 1]?.stepTime : undefined;
                    const formattedStepTimeDifference = nextStepTime !== undefined ? ((nextStepTime - stepInfo.stepTime) / 1000).toFixed(1) : '-';

                    return <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{stepInfo.step.name}</TableCell>
                        <TableCell>{stepInfo.stepTime}</TableCell>
                        <TableCell>{stepInfo.step.channel}</TableCell>
                        <TableCell><code>{stepInfo.step.flowPressure}</code></TableCell>
                        <TableCell><code>{stepInfo.step.degasserPressure}</code></TableCell>
                        <TableCell>{formattedStepTimeDifference}</TableCell>
                        <TableCell><code>{stepInfo.step.escapeWhen}</code></TableCell>
                    </TableRow>
                })
            }
        </TableBody>
    </Table>
}


const StepTableHeadingRow = () => {
return <TableHead>
    <TableRow>
      <TableCell as="th">Step number</TableCell>
      <TableCell as="th">Step name</TableCell>
      <TableCell as="th">Time offset (ms)</TableCell>
      <TableCell as="th">Channel</TableCell>
      <TableCell as="th">Requested Flow Pressure (mBar)</TableCell>
      <TableCell as="th">Requested Degasser Pressure (mBar)</TableCell>
      <TableCell as="th">Step time (s)</TableCell>
      <TableCell as="th"><code>escapeWhen</code></TableCell>
    </TableRow>
  </TableHead>
}

