import { useState } from 'react'

import { DownloadStatusType } from "../../utilities/DownloadStatusType"
import { jsonParses } from './methods/jsonParses'
import { UnknownValidationError, ValidationError } from './ValidationError'
import { Configuration } from '../../types/Configuration'
import { spotsCorrect } from './methods/spotsCorrect'
import { triggersCorrect } from './methods/triggersCorrect'
import { uniqueNames } from './methods/uniqueNames'
import { refractiveIndexChangeDetector } from './methods/refractiveIndexChangeDetector'
import { refractiveIndexChangeFlowCalculator } from './methods/refractiveIndexChangeFlowCalculator'
import { invalidNames } from './methods/invalidNames'
import { emptyStrings } from './methods/emptyStrings'
import { dryWetChangeDetector } from './methods/dryWetChangeDetector'
import { dryWetFlowRateCalculator } from './methods/dryWetFlowRateCalculator'
import { detectChangeInDegasserEffort } from './methods/detectChangeInDegasserEffort'

/**
 * Validates a configuration file according to the M3 configuration spec.
 * @param fileContents The file contents, expected to be a json file represented as a string.
 * @returns A list of errors, empty if there are none.
 */
const validateConfiguration = (fileContents: string): ValidationError[] => {
    try {
        const configuration = jsonParses(fileContents)
        const hasErrors = Array.isArray(configuration)
        if (hasErrors) {
            return configuration
        }
        return [
            // Implementation checks
            spotsCorrect(configuration),
            triggersCorrect(configuration),
            
            // Routine checks
            refractiveIndexChangeDetector(configuration),
            refractiveIndexChangeFlowCalculator(configuration),
            dryWetChangeDetector(configuration),
            dryWetFlowRateCalculator(configuration),
            detectChangeInDegasserEffort(configuration),
            
            // Generic value checks
            uniqueNames(configuration),
            invalidNames(configuration),
            emptyStrings(configuration),
        ]
        .flat()
    } catch (error: any) {
        if (error instanceof ValidationError) {
            return [error]
        } else {
            console.error(error)
            return [new UnknownValidationError(error)]
        }
    }
}

/**
 * Validates configuration files provided to it, returning errors as state.
 * @param configuration 
 * @returns The errors from the last configuration validation, and a function to validate configurations before updating them.
 */
export const useConfigurationValidation = (configuration: DownloadStatusType<Configuration>): [errors: ValidationError[], validateConfigurationAction: (fileContents: string) => ValidationError[]] => {
    const [errors, setErrors] = useState<ValidationError[]>([])

    const validateConfigurationAction = (fileContents: string) => {
        const validationErrors = validateConfiguration(fileContents)
        setErrors(validationErrors)
        return validationErrors
    }

    return [errors, validateConfigurationAction]
}
