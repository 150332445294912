import { downloadData } from 'aws-amplify/storage';
import { FlowTestResult } from '../types/flowTestResult'
import { SpotsTime } from '../types/spotsTimeSeries'

export const downloadJsonFileFromS3 = async<T, > (filename: string): Promise<T> => {
    try {
        const text = await downloadTextFileFromS3(filename)
        const json = JSON.parse(text)
        return json as T
    } catch (error: any) {
        console.error("error: ", error)
        throw error
    }   
}

export const downloadTextFileFromS3 = async (filename: string): Promise<string> => {
    try {
        const downloadResult = await downloadData({key: filename }).result
        const text = await downloadResult.body.text() // body.json() resulted in error: "parsing response to JSON is not implemented. Please use response.text()"
        return text
    } catch (error: any) {
        console.error("error: ", error)
        throw error
    } 
}

export const initiateOneSecondTimeSeriesDownload = async (filename: string, path: string) => {
    const spotsFilename = filename.replace("chipflowTest", "spotsOneSecondTimeSeries");

    const fileContents = await downloadTextFileFromS3(`${path}/${spotsFilename}.json`)
    initiateDataDownload(fileContents, `${spotsFilename}.json`, "application/json") 
}

export const initiateVariablesTimeSeriesDownload = async (filename: string, path: string) => {
    const variablesFilename = filename.replace("chipflowTest", "chipflowTestVariables");

    const fileContents = await downloadTextFileFromS3(`${path}/${variablesFilename}.json`)
    initiateDataDownload(fileContents, `${variablesFilename}.json`, "application/json") 
}

export const initiateSpotFramesDownload = async (filename: string, path: string) => {
    const spotFramesFilename = filename.replace("chipflowTest", "spotsFrames");

    const fileContents = await downloadTextFileFromS3(`${path}/${spotFramesFilename}.json`)
    initiateDataDownload(fileContents, `${spotFramesFilename}.json`, "application/json") 
}

export const initiateDryWetTransitionDownload = async (filename: string, path: string) => {
    const fixedFilename = filename.replace("chipflowTest", "dryWetTransitionTimeSeries");

    const fileContents = await downloadTextFileFromS3(`${path}/${fixedFilename}.json`)
    initiateDataDownload(fileContents, `${fixedFilename}.json`, "application/json") 
}

export const initiateConfigurationDownload = async (path: string) => {
    const fileContents = await downloadTextFileFromS3(path)
    const parts = path.split('/');
    const filename = parts[parts.length - 1];
    initiateDataDownload(fileContents, filename, "application/json") 
}

export const initiateSpotsOneSecondTimeSeriesCSVDownload = async (filename: string, path: string) => {
    const spotsFilename = filename.replace("chipflowTest", "spotsOneSecondTimeSeries");
    const fileContents: SpotsTime[] = await downloadJsonFileFromS3(`${path}/${spotsFilename}.json`)
    const csvString = createTimeSeriesCSV(fileContents, ",")
    initiateDataDownload(csvString, `${filename}.csv`)
}

const createTimeSeriesCSV = (spotsTimeSeries: SpotsTime[], separator: string) : string => {
    let headerColumns = ["Time","r","g","b"]

    const rowLines = spotsTimeSeries.map(reading => {
        let rowData = [`${reading.time}`,`${reading.spots[0].r}`,`${reading.spots[0].g}`,`${reading.spots[0].b}`]
        return rowData.join(separator)
    })
    return headerColumns.join(separator) + "\n" + rowLines.join("\n")
}

export const initiateCombinedCSVDownload = async (filename: string, flowResults: FlowTestResult, path: string) => {
    const spotsFilename = filename.replace("chipflowTest", "spotsTimeSeries");
    const spotsTimeSeries: SpotsTime[] = await downloadJsonFileFromS3(`${path}/${spotsFilename}.json`)
    const csvString = createCombinedCSV(flowResults, spotsTimeSeries)
    const downloadFilename = filename.replace("chipflowTest", "combined")
    initiateDataDownload(csvString, `${downloadFilename}.csv`)
}

const createCombinedCSV = (flowResults: FlowTestResult, spotsTimeSeries: SpotsTime[]) : string => {
    let flowTimes = flowResults.readings.map(reading => reading.time)
    let spotsTimes = spotsTimeSeries.map(reading => reading.time)
    let combinedTimes = flowTimes.concat(spotsTimes)
    let combinedTimesSet = new Set(combinedTimes)
    let combinedTimesArray = Array.from(combinedTimesSet).sort((a,b) => a-b)
    let combinedCSVHeader = "Time,Flow Meter,Flow Pump Pressure,Degasser Pump Pressure,Flow Pump Effort,Degasser Pump Effort,r,g,b\n"
    let flowAsTimeDict = flowResults.readings.reduce((acc, reading) => {
        acc[reading.time] = reading
        return acc
    }, {} as {[key: number]: any})
    let spotsAsTimeDict = spotsTimeSeries.reduce((acc, reading) => {
        acc[reading.time] = reading
        return acc
    }, {} as {[key: number]: any})
    let rows = combinedTimesArray.map(time => {
        let flowReading = flowAsTimeDict[time]
        let spotsReading = spotsAsTimeDict[time]
        let flowRow = flowReading ? `${flowReading.time},${flowReading.flowMeter},${flowReading.flowPump},${flowReading.degasserPump},${flowReading.flowEffort},${flowReading.degasserEffort},,,` : null
        let spotsRow = spotsReading ? `${spotsReading.time},,,,,,${spotsReading.spots[0].r},${spotsReading.spots[0].g},${spotsReading.spots[0].b}` : null
        return flowRow ?? spotsRow
    })
    return combinedCSVHeader + rows.join("\n")
}

export const initiateOverviewCSVDownload = async (flowResults: FlowTestResult, filename: string) => {  
    const csvString = createFlowResultsCSV(flowResults, ',')
    initiateDataDownload(csvString, filename)
}

const createFlowResultsCSV = (flowResults: FlowTestResult, separator: string) : string => {
    let headerColumns = ["Time","Flow Meter","Flow Pump Pressure"]
    if (flowResults.readings[0].degasserPump !== undefined) {
        headerColumns.push("Degasser Pump Pressure")
        headerColumns.push("Flow Pump Effort")
        headerColumns.push("Degasser Pump Effort")
    }
    const rowLines = flowResults.readings.map(reading => {
        let rowData = [`${reading.time}`,`${reading.flowMeter}`,`${reading.flowPump}`]
        if (reading.degasserPump !== undefined) {
            rowData.push(`${reading.degasserPump}`)
            rowData.push(`${reading.flowEffort}`)
            rowData.push(`${reading.degasserEffort}`)
        }
        return rowData.join(separator)
    })
    return headerColumns.join(separator) + "\n" + rowLines.join("\n")
}

/**
 * Initiates the download of an arbitrary target variable.
 * @param obj The object to initiate a file download of.
 * @param baseFilename The base file name that contains the word 'chipflowTest' we will substitute.
 * @param desiredFilename The name to substitute for 'chipflowTest' in filename.
 */
export const initiateVariableDownload = (obj: object, baseFilename: string, desiredFilename: string) => {
    const stringObj = JSON.stringify(obj)
    const filename = `${baseFilename.replace("chipflowTest", desiredFilename)}.json`
    initiateDataDownload(stringObj, filename, "application/json")
}

// inspired by: js-file-download
// https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
// used for download links
export const initiateDataDownload = (data: string | Blob, filename: string, mimeType: string = 'application/vnd.ms-excel') => {
    const blob = (typeof data === 'string') 
        ? new Blob([data], {type : mimeType}) 
        : data;
    const blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }
  
    document.body.appendChild(tempLink);
    tempLink.click();
  
    // Fixes "webkit blob resource error 1"
    setTimeout(function() {
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
    }, 200)
}

